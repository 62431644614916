import SlideExperience, { SlideType } from './SlideExperience';
import '../styles/Experience.css';

export default function	Experience() {
	return (
		<div id="experiences" className="bg-crowberryBlue p-[3%] px-[5%]">
			<div className="expTitlePart flex">
				<h1 className="partTitle text-white first-letter:text-grassStainGreen text-[80px] mr-[20px]">Expériences</h1>
				<span className='titleLine mr-[20px]'></span>
				<h1 className="partTitle titleProject text-white first-letter:text-grassStainGreen text-[80px]">Projets</h1>
			</div>
			<span className="underlinedContent !w-[650px] bg-grassStainGreen"></span>

			<div className="flex flex-wrap justify-center pt-[60px] mb-[50px]">
				<SlideExperience
					type={SlideType.School}
					title="Plateforme de jeu multijoueur en temps réel"
					content="Reproduction moderne du jeu Pong, intégrant une plateforme multijoueur complète.
					Ce projet comprend un système d'authentification sécurisé, une messagerie instantanée en temps réel,
					ainsi qu'une gestion avancée des amis et des profils utilisateur."
					techs={["Svelte", "NestJS", "Socket.io"]}
				/>
				<SlideExperience 
					type={SlideType.Personnal}
					title="Gestion des matchs automatisés de badminton"
					content="Automatisation de la gestion des joueurs et des matchs lors de rencontres internes.
					L'application a été développée pour remplacer la gestion manuelle sur papier des tournois amicaux, offrant ainsi une solution plus efficace.
					Un format web est actuellement en développement."
					techs={["C++98"]}
				/>
				<SlideExperience 
					type={SlideType.School}
					title="Serveur IRC"
					content="Création d'un serveur IRC (Internet Relay Chat) pouvant communiquer avec un client IRC existant
					(irssi pour ce projet). Le serveur permet la communication entre plusieurs utilisateurs permettant
					d'échanger des messages en temps réel."
					techs={["C++98", "Sockets"]}
				/>
				<SlideExperience 
					type={SlideType.School}
					title="CTF - rainfall 42"
					content="Ce projet est une introduction modeste au monde de la cybersécurité.
					Il a pour but de découvrir, à travers plusieurs petits challenges, les défis que peuvent
					présenter la cybersécurité dans des domaines variés."
					techs={["Cybersécurité", "Virtualbox"]}
				/>
			</div>
			<span className="text-white text-[30px]">
				<span className="text-grassStainGreen">R</span>etrouvez la liste complète de mes projets publics sur <a className="underline text-grassStainGreen hover:text-green-300" href="https://github.com/William-Dbt">Github</a> !
			</span>
		</div>
	);
};
