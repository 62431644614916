// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactFields {
	height: 50px;
	min-width: 600px;

	margin-top: 50px;
	padding: 20px;

	border: #BDF337 2px solid;
	border-radius: 5px;
	font-size: 20px;
	line-height: 24px;
	color: #003748;
}

.labels {
	position: absolute;

	margin-top: 15px;

	font-size: 24px;
	color: white;
}

.labels::first-letter {
	color: #BDF337;
}

.submitButton {
	width: 150px;
	margin-top: 20px;

	background-color: white;
	border: 2px solid #BDF337;
	border-radius: 30px;
	box-shadow: #BDF337 4px 4px 0 0;

	color: #003748;
	font-weight: 600;
	font-size: 20px;
	line-height: 50px;
	cursor: pointer;
}

.submitButton:hover {
	background-color: #f4ffd8;
}

.submitButton:active {
	box-shadow: #BDF337 2px 2px 0 0;
	transform: translate(2px, 2px);
}

.line {
	height: 700px;
	width: 4px;

	margin-top: auto;
	margin-bottom: auto;

	content: "";
	background-color: #BDF337;
}

@media screen and (max-width: 600px) {
	.contactFields {
		min-width: 0px;
		width: 300px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/styles/Contact.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,gBAAgB;;CAEhB,gBAAgB;CAChB,aAAa;;CAEb,yBAAyB;CACzB,kBAAkB;CAClB,eAAe;CACf,iBAAiB;CACjB,cAAc;AACf;;AAEA;CACC,kBAAkB;;CAElB,gBAAgB;;CAEhB,eAAe;CACf,YAAY;AACb;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,YAAY;CACZ,gBAAgB;;CAEhB,uBAAuB;CACvB,yBAAyB;CACzB,mBAAmB;CACnB,+BAA+B;;CAE/B,cAAc;CACd,gBAAgB;CAChB,eAAe;CACf,iBAAiB;CACjB,eAAe;AAChB;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,+BAA+B;CAC/B,8BAA8B;AAC/B;;AAEA;CACC,aAAa;CACb,UAAU;;CAEV,gBAAgB;CAChB,mBAAmB;;CAEnB,WAAW;CACX,yBAAyB;AAC1B;;AAEA;CACC;EACC,cAAc;EACd,YAAY;CACb;AACD","sourcesContent":[".contactFields {\n\theight: 50px;\n\tmin-width: 600px;\n\n\tmargin-top: 50px;\n\tpadding: 20px;\n\n\tborder: #BDF337 2px solid;\n\tborder-radius: 5px;\n\tfont-size: 20px;\n\tline-height: 24px;\n\tcolor: #003748;\n}\n\n.labels {\n\tposition: absolute;\n\n\tmargin-top: 15px;\n\n\tfont-size: 24px;\n\tcolor: white;\n}\n\n.labels::first-letter {\n\tcolor: #BDF337;\n}\n\n.submitButton {\n\twidth: 150px;\n\tmargin-top: 20px;\n\n\tbackground-color: white;\n\tborder: 2px solid #BDF337;\n\tborder-radius: 30px;\n\tbox-shadow: #BDF337 4px 4px 0 0;\n\n\tcolor: #003748;\n\tfont-weight: 600;\n\tfont-size: 20px;\n\tline-height: 50px;\n\tcursor: pointer;\n}\n\n.submitButton:hover {\n\tbackground-color: #f4ffd8;\n}\n\n.submitButton:active {\n\tbox-shadow: #BDF337 2px 2px 0 0;\n\ttransform: translate(2px, 2px);\n}\n\n.line {\n\theight: 700px;\n\twidth: 4px;\n\n\tmargin-top: auto;\n\tmargin-bottom: auto;\n\n\tcontent: \"\";\n\tbackground-color: #BDF337;\n}\n\n@media screen and (max-width: 600px) {\n\t.contactFields {\n\t\tmin-width: 0px;\n\t\twidth: 300px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
