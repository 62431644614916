// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleLine {
	margin-top: 20px;

	height: 70px;
	width: 4px;

	content: "";
	background-color: #BDF337;
}

@media screen and (max-width: 800px) {
	.titleLine {
		display: none;
	}

	.expTitlePart {
		flex-direction: column;
	}

	.titleProject {
		display: none;
	}
}
`, "",{"version":3,"sources":["webpack://./src/styles/Experience.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;;CAEhB,YAAY;CACZ,UAAU;;CAEV,WAAW;CACX,yBAAyB;AAC1B;;AAEA;CACC;EACC,aAAa;CACd;;CAEA;EACC,sBAAsB;CACvB;;CAEA;EACC,aAAa;CACd;AACD","sourcesContent":[".titleLine {\n\tmargin-top: 20px;\n\n\theight: 70px;\n\twidth: 4px;\n\n\tcontent: \"\";\n\tbackground-color: #BDF337;\n}\n\n@media screen and (max-width: 800px) {\n\t.titleLine {\n\t\tdisplay: none;\n\t}\n\n\t.expTitlePart {\n\t\tflex-direction: column;\n\t}\n\n\t.titleProject {\n\t\tdisplay: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
