import { useState, useEffect, useRef } from 'react';
import logo from '../assets/logos/logo-green-800x600.png';
import '../styles/Header.css';

export default function Header() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const menuRef = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		// Handler for clicking outside
		const handleClickOutside = (event: MouseEvent) => {
			if (isMenuOpen && 
				menuRef.current && 
				buttonRef.current && 
				!menuRef.current.contains(event.target as Node) && 
				!buttonRef.current.contains(event.target as Node)) {
				setIsMenuOpen(false);
			}
		};

		// Add event listener
		document.addEventListener('mousedown', handleClickOutside);

		// Cleanup
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isMenuOpen]);

	return (
		<>
			<header className='header'>
				<img src={logo} width={100} height={75} alt='Logo' className='headerLogo' />
				
				<button 
					ref={buttonRef}
					className={`hamburgerButton ${isMenuOpen ? 'active' : ''}`}
					onClick={() => setIsMenuOpen(!isMenuOpen)}
				>
					<span></span>
					<span></span>
					<span></span>
				</button>

				<nav ref={menuRef} className={`navBlock flex ml-auto items-center ${isMenuOpen ? 'active' : ''}`}>
					<ul className='navigationMenu flex'>
						<li>
							<a className='navItem' href="#home">Page d'accueil</a>
						</li>
						<li>
							<a className='greenElement navItem' href="#about">À propos</a>
						</li>
						<li>
							<a className='navItem' href="#skills">Compétences</a>
						</li>
						<li>
							<a className='greenElement navItem' href="#experiences">Expériences</a>
						</li>
						<li>
							<a className='navItem' href="#contact">Contact</a>
						</li>
						{/* <button className='translateButton text-[24px] ml-[30px]'>🇺🇸</button> */}
					</ul>
				</nav>
			</header>
			<div className="headerSpacing" />
		</>
	);
}
