import HammerPicture from "../assets/hammer-valve.jpg";
import PandaPicture from "../assets/interrogation.png";
import DevelopperPicture from "../assets/developper.jpg";
import ProgrammationPicture from "../assets/programmation.jpg";
import "../styles/About.css";
import "../index.css";

export default function	About() {
	return (
		<div id="about" className="bg-grassStainGreen flex flex-col">
			<div className="px-[5%] py-[5%]">
				<h1 className="partTitle text-crowberryBlue text-[80px]">À propos de moi</h1>
				<span className="underlinedContent bg-crowberryBlue"></span>

				{/* 2014 part */}
				<div className="fstPart flex items-center place-content-around mt-[5%]">
					{/* Content part */}
					<div className="sideContent w-[370px] text-crowberryBlue">
						{/* Explain part */}
						<span className="contentLine bg-gradient-to-b from-crowberryBlue to-grassStainGreen" />
						<div className="explain ml-[20px]">
							<div className="flex items-center place-content-between">
								<h1 className="text-[28px] font-[Langar]">2014</h1>
								<span className="font-[Langar]">Curiosité</span>
							</div>
							<p className="explainPart">
								À 14 ans, je réalise mes premiers pas dans la modélisation 3D via des logiciels de création de cartes
								pour le jeu Counter-Strike:Source
							</p>
						</div>
						<div className="improvementPart bg-crowberryBlue text-white">
							<p>
								+ Développement créatif et artistique<br />
								+ Premières interactions semi-professionnelles avec des associations souhaitant
								intégrer des cartes personnalisées à leurs serveurs
							</p>
						</div>
					</div>
					<img className="hammerPicture" src={HammerPicture} width={666} height={375} alt="Hammer software" />
				</div>
			</div>
			
			<div className="bg-crowberryBlue px-[5%] py-[5%]">
				{/* 2014-2015 part */}
				<div className="flex items-center place-content-around">
					<img className="sidePicture" src={PandaPicture} width={396} height={413} alt="Questionned Panda" />
					{/* Content part */}
					<div className="sideContent w-[370px] text-grassStainGreen">
						{/* Explain part */}
						<span className="contentLine bg-gradient-to-b from-grassStainGreen to-crowberryBlue" />
						<div className="explain ml-[20px]">
							<div className="flex items-center place-content-between">
								<h1 className="text-[28px] font-[Langar]">2014 à 2015</h1>
								<span className="font-[Langar]">Comment ça marche ?</span>
							</div>
							<p className="explainPart">
								Découverte du fonctionnement d'un serveur de jeu et configuration de ces derniers
								via des plugins accessible publiquement
							</p>
						</div>
						<div className="improvementPart bg-grassStainGreen text-crowberryBlue">
							<p>
								+ Premières expériences dans le domaine du support informatique<br />
								+ Développement du sens du relationnel : demandes formulés par les joueurs et réponses
								du configurateur par l'application des demandes<br />
								+ Développement autodidacte dans la résolution de problèmes et la recherche de solutions
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="bg-crowberryBlue px-[5%] py-[5%]">
				{/* 2015-2019 part */}
				<div className="flex items-center place-content-around">
					{/* Content part */}
					<div className="sideContent w-[370px] text-grassStainGreen">
						{/* Explain part */}
						<img className="darkPlacePicture ml-auto mr-auto hidden" src={ProgrammationPicture} width={700} alt="Questionned Panda" />
						<span className="contentLine !h-[125px] bg-gradient-to-b from-grassStainGreen to-crowberryBlue" />
						<div className="explain ml-[20px]">
							<div className="flex flex-col">
								<h1 className="text-[28px] font-[Langar]">2015 à 2019</h1>
								<span className="text-[30px] font-[Langar]">The dark place</span>
								</div>
							<p className="explainPart">
								Apprentissage de la programmation et développement de plugins en SourcePawn<br />
								<br />
								Configuration et développement de modes autonomes respectant les demandes des joueurs
							</p>
						</div>
						<div className="improvementPart bg-grassStainGreen text-crowberryBlue">
							<p>
								+ Premiers pas en R&D<br />
								+ Apprentissage et application des bases du clean-code<br />
								+ Tests et debugage de mes premiers plugins<br />
								+ Développement de mon esprit critique : "Qu'est-ce que je pourrais améliorer ?",
								"Est-ce que la personne qui me lira comprendra ce que je souhaite faire ?"...
							</p>
						</div>
					</div>
					<img className="sidePicture" src={DevelopperPicture} width={400} alt="Human who's progamming" />
				</div>
			</div>

			{/* 2019-2021 part */}
			<div className="bg-crowberryBlue flex flex-col items-center py-[5%] text-grassStainGreen">
				<h1 className="font-[Langar] text-[28px]">2019 à 2021</h1>
				<p className="lastPartIntroduction text-[25px] px-[10%] text-center">
					Cette période intervient après l'obtention de mon BAC S (option Informatique et
					Sciences du Numérique) et avant mon entrée à l'école 42.
					Elle avait pour but de me permettre d'acquérir diverses compétences à travers plusieurs
					travails étudiants comme :
				</p>
				<div className="jobList text-[30px] text-crowberryBlue flex place-content-between text-center py-[20px] leading-[25px]">
					<span className="jobBox">Chauffeur Livreur VL</span>
					<span className="jobBox">Préparateur de commandes</span>
					<span className="jobBox">Employé Polyvalent</span>
				</div>
				<p className="text-[22px] mx-[6%] p-[25px] mt-[2%] bg-grassStainGreen text-crowberryBlue rounded-[30px] leading-[25px]">
					Toutes ces expériences m'ont permis de prendre en maturité et de comprendre rapidement
					les rouages du monde professionnel.<br />
					Outre les compétences non-techniques que j'ai pu acquérir, elles m'ont permis d'apprendre
					à m'adapter rapidement et efficacement aux différents environnements de travail, tout en collaborant
					avec des équipes variées pour atteindre des objectifs communs.
				</p>
			</div>

			<div className="bg-grassStainGreen px-[5%] py-[5%]">
				{/* 2021-now part */}
				<div className="flex items-center place-content-around">
					<img className="sidePicture" src={ProgrammationPicture} width={700} alt="Questionned Panda" />
					{/* Content part */}
					<div className="sideContent w-[500px] text-crowberryBlue">
						{/* Explain part */}
						<span className="contentLine !h-[150px] bg-gradient-to-b from-crowberryBlue to-grassStainGreen" />
						<div className="explain ml-[20px]">
							<div className="flex items-center place-content-between">
								<h1 className="text-[28px] font-[Langar]">2021 à aujourd'hui</h1>
								<span className="font-[Langar]">L'école 42</span>
							</div>
							<p className="explainPart">
								Développement de mes compétences techniques en intégrant l'école 42 Paris.<br />
								Cette dernière est une école privée proposant une formation en peer-to-peer par projet.<br /><br />
								La première partie du cursus repose sur les fondamentaux de la programmation proposant divers projets
								mettant en avant les principes suivants : DRY, KISS, YAGNI, SOLID et bien d'autre ...<br />
								La seconde repose sur la professionalisation et la spécialisation dans diverses branches comme la
								Cybersécurité, la Datascience, le jeu-vidéo etc...
							</p>
						</div>
						<div className="improvementPart bg-crowberryBlue text-white">
							<p>
								+ Développement de ma persévérance et de ma résilience, l'intensité du cursus développe
								une capacité à rebondir face aux défis techniques et aux échecs<br />
								+ Amélioration de ma capacité d'adaptation et d'apprentissage continu<br />
								+ Capacité à s'adapter rapidement aux technologies et outils diversifiés
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
