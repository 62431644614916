import React, { useState, useRef } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import "../styles/Contact.css";

export default function	Contact() {
	const recaptcha = useRef<ReCAPTCHA>(null);
	const [inputFields, setInputFields] = useState({
		fullName: "",
		email: "",
		object: "",
		message: ""
	});
	const [isSubmitButtonOn, setIsSubmitButtonOn] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");

	let SITE_KEY: string = "";
	if (process.env.REACT_APP_SITE_KEY)
		SITE_KEY = process.env.REACT_APP_SITE_KEY;
	else
		throw new Error("REACT_APP_SITE_KEY env variable is not defined.");

	// Change the object inputFields with the new elements of the form when changing it
	function handleChangeInput(event: React.FormEvent<HTMLInputElement>) {
		event.preventDefault();
		setInputFields({ ...inputFields, [event.currentTarget.name]: event.currentTarget.value })
	}

	function handleChangeTextArea(event: React.FormEvent<HTMLTextAreaElement>) {
		event.preventDefault();
		setInputFields({ ...inputFields, [event.currentTarget.name]: event.currentTarget.value })
	}

	async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		let sendMailRoute: string = `${process.env.REACT_APP_API_URL!}send-message`;
		if (isSubmitButtonOn) {
			try {
				const response = await fetch(sendMailRoute, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(inputFields),
				});

				const data = await response.json();
				if (data.error?.length > 0) {
					if (successMessage.length > 0)
						setSuccessMessage("");

					setErrorMessage("Une erreur est survenue lors de l'envoie du message.\nMerci de l'envoyer manuellement à : william.debotte.pro@gmail.com");
				}
				else if (data.success) {
					if (errorMessage.length > 0)
						setErrorMessage("");

					setSuccessMessage("Message envoyé avec succès ! Une réponse vous parviendra dès que possible.");
					inputFields.email = "";
					inputFields.fullName = "";
					inputFields.message = "";
					inputFields.object = "";
				}
			}
			catch (error) {
				if (successMessage.length > 0)
					setSuccessMessage("");

				setErrorMessage("Une erreur est survenue lors de l'envoie du message.\nMerci de l'envoyer manuellement à : william.debotte.pro@gmail.com");
			}
		}
		else {
			if (successMessage.length > 0)
				setSuccessMessage("");

			setErrorMessage("Merci de compléter la vérification captcha.");
		}
	}

	function handleCaptchaChange() {
		if (recaptcha.current) {
			const token = recaptcha.current.getValue();
			if (token)
				setIsSubmitButtonOn(token.length > 0);
		}
		else
			setIsSubmitButtonOn(false);
	}

	return (
		<div id="contact" className="bg-crowberryBlue p-[3%] px-[5%]">
			<h1 className="text-white first-letter:text-grassStainGreen text-[80px] mr-[20px]">Contact</h1>
			<span className="underlinedContent bg-grassStainGreen"></span>
			<p className="first-letter:text-grassStainGreen mb-[30px] text-white text-[45px] leading-[46px] mt-[30px]">
				Un projet en tête et vous souhaitez en parler ?<br />
				N'hésitez pas à me contacter !
			</p>
			<div className="flex place-content-center mt-[50px]">
				<div className="mb-10">
					<span className="text-white text-[50px] block first-letter:text-grassStainGreen">
						Envoyer un message
					</span>
					<form className="flex flex-col items-center" onSubmit={handleSubmit}>
						<div>
							<label className="labels">Nom</label>
							<input className="contactFields"
								type="text"
								name="fullName"
								value={inputFields.fullName}
								onChange={handleChangeInput}
								required
							/>
						</div>
						<div>
							<label className="labels">Email</label>
							<input className="contactFields"
								type="email"
								name="email"
								value={inputFields.email}
								onChange={handleChangeInput}
								required
							/>
						</div>
						<div>
							<label className="labels">Objet</label>
							<input className="contactFields"
								type="text"
								name="object"
								value={inputFields.object}
								onChange={handleChangeInput}
								required
							/>
						</div>
						<div>
							<label className="labels">Message</label>
							<textarea className="contactFields min-h-[300px]"
								name="message"
								value={inputFields.message}
								onChange={handleChangeTextArea}
								required
							/>
						</div>
						<ReCAPTCHA className="mt-[10px]"
							ref={recaptcha}
							sitekey={SITE_KEY}
							onChange={handleCaptchaChange}
							onExpired={() => { setIsSubmitButtonOn(false) }}
						/>
						<div className="mt-[10px] text-[20px]">
							{errorMessage ? <span className="text-red-500">{errorMessage}</span> : null}
							{successMessage ? <span className="text-green-500">{successMessage}</span> : null}
						</div>
						<input className="submitButton" type="submit" name="sendMessage" value="Envoyer" />
					</form>
				</div>
			</div>
		</div>
	);
};
