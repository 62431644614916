// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentLine {
	position: absolute;
	height: 90px;

	width: 6px;
	content: "";
}

.jobBox {
	margin-inline: 30px;
	padding-inline: 10px;
	padding-top: 10px;
	padding-bottom: 10px;

	background-color: #BDF337;
	width: 190px;

	border-radius: 16px;
}

.explainPart {
	font-size: 25px;
	margin-bottom: 5%;
	line-height: 25px;
}

.improvementPart {
	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;
	border-bottom-left-radius: 16px;

	font-size: 22px;
	padding: 20px;
	line-height: 20px;
}

@media screen and (max-width: 1350px) {
	.sidePicture {
		display: none;
	}

	.fstPart {
		flex-direction: column;
	}

	.sideContent {
		width: 100%;
	}

	.explain {
		margin-right: 15px;
	}

	.hammerPicture {
		margin-top: 30px;
	}

	.darkPlacePicture {
		display: block;
		margin-bottom: 40px;
	}

	.lastPartIntroduction {
		line-height: 30px;
	}

	.jobBox {
		margin-bottom: 15px;
	}
}

@media screen and (max-width: 800px) {
	.jobList {
		flex-direction: column;
	}
}
`, "",{"version":3,"sources":["webpack://./src/styles/About.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,YAAY;;CAEZ,UAAU;CACV,WAAW;AACZ;;AAEA;CACC,mBAAmB;CACnB,oBAAoB;CACpB,iBAAiB;CACjB,oBAAoB;;CAEpB,yBAAyB;CACzB,YAAY;;CAEZ,mBAAmB;AACpB;;AAEA;CACC,eAAe;CACf,iBAAiB;CACjB,iBAAiB;AAClB;;AAEA;CACC,6BAA6B;CAC7B,gCAAgC;CAChC,+BAA+B;;CAE/B,eAAe;CACf,aAAa;CACb,iBAAiB;AAClB;;AAEA;CACC;EACC,aAAa;CACd;;CAEA;EACC,sBAAsB;CACvB;;CAEA;EACC,WAAW;CACZ;;CAEA;EACC,kBAAkB;CACnB;;CAEA;EACC,gBAAgB;CACjB;;CAEA;EACC,cAAc;EACd,mBAAmB;CACpB;;CAEA;EACC,iBAAiB;CAClB;;CAEA;EACC,mBAAmB;CACpB;AACD;;AAEA;CACC;EACC,sBAAsB;CACvB;AACD","sourcesContent":[".contentLine {\n\tposition: absolute;\n\theight: 90px;\n\n\twidth: 6px;\n\tcontent: \"\";\n}\n\n.jobBox {\n\tmargin-inline: 30px;\n\tpadding-inline: 10px;\n\tpadding-top: 10px;\n\tpadding-bottom: 10px;\n\n\tbackground-color: #BDF337;\n\twidth: 190px;\n\n\tborder-radius: 16px;\n}\n\n.explainPart {\n\tfont-size: 25px;\n\tmargin-bottom: 5%;\n\tline-height: 25px;\n}\n\n.improvementPart {\n\tborder-top-right-radius: 16px;\n\tborder-bottom-right-radius: 16px;\n\tborder-bottom-left-radius: 16px;\n\n\tfont-size: 22px;\n\tpadding: 20px;\n\tline-height: 20px;\n}\n\n@media screen and (max-width: 1350px) {\n\t.sidePicture {\n\t\tdisplay: none;\n\t}\n\n\t.fstPart {\n\t\tflex-direction: column;\n\t}\n\n\t.sideContent {\n\t\twidth: 100%;\n\t}\n\n\t.explain {\n\t\tmargin-right: 15px;\n\t}\n\n\t.hammerPicture {\n\t\tmargin-top: 30px;\n\t}\n\n\t.darkPlacePicture {\n\t\tdisplay: block;\n\t\tmargin-bottom: 40px;\n\t}\n\n\t.lastPartIntroduction {\n\t\tline-height: 30px;\n\t}\n\n\t.jobBox {\n\t\tmargin-bottom: 15px;\n\t}\n}\n\n@media screen and (max-width: 800px) {\n\t.jobList {\n\t\tflex-direction: column;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
