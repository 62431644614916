import profilePicture from "../assets/photo_profile.jpeg";
import decorationPicture from "../assets/programmer.jpg";
import "../styles/Home.css";

export default function	Home() {
	return (
		<div id="home" className="home bg-crowberryBlue flex px-[3%] pt-[5%] pb-[120px]">
			{/* Left side */}
			<div className="leftSide w-2/4">
				{/* Part with profile picture and name, in line */}
				<div className="namePart flex items-center"> 
					<img className="profilePicture rounded-xl"
						src={profilePicture}
						width={169}
						height={176}
						alt="William Debotte"
					/>
					{/* Personal name */}
					<div className="nameText flex flex-col ml-[35px] space-y-[80px]">
						<span className="nameTitle text-grassStainGreen text-[100px] leading-[0px]">William</span>
						<span className="nameTitle text-white text-[110px] leading-[0px]">Debotte</span>
					</div>
				</div>
				<span className="separatedLine"></span>

				{/* Part with introduction text */}
				<div className="introductionPart mt-[40px]">
					<h1 className="text-white text-[70px] leading-[60px] pb-[30px] pt-[15px]">
						<span className="text-grassStainGreen">D</span>éveloppeur <span className="text-grassStainGreen">J</span>unior
					</h1>
					<p className="text-white text-[30px]">
						Je me présente, <span className="text-grassStainGreen">William</span>, Développeur d'applications web et de solutions personnalisées.
						Je fais partie des passionnés d'informatique ayant mis les mains dans la programmation pendant l'adolescence.
						Ma curiosité s'est rapidement transformée en un véritable moteur d'exploration et d'apprentissage.
						<br /><br />
						Motivé à l'idée d'apprendre et d'acquérir de nouvelles compétences, je suis disponible pour échanger sur des projets techniques et apporter mon expertise dans le développement de solutions innovantes.
						<br />
						N'hésitez pas à me <span className="text-grassStainGreen">contacter</span> !
					</p>
				</div>
			</div>

			{/* Right side */}
			<div className="flex flex-col m-auto items-center bottom-0">
				<img className="programmerImg rounded-3xl"
					src={decorationPicture}
					width={588}
					height={409}
					alt="Programmer who's progamming"
				/>
				<a href="#contact">
					<button className="contactButton">Parlons-en</button>
				</a>
			</div>
		</div>
	);
};
