import ReactLogo from '../assets/logos/react.png';
import SvelteLogo from '../assets/logos/svelte.png';
import TailwindLogo from '../assets/logos/tailwindcss.png';
import NestJSLogo from '../assets/logos/nestjs.png';
import PrismaLogo from '../assets/logos/prisma.png';
import CLogo from '../assets/logos/c.png';
import CPPLogo from '../assets/logos/c++.png';
import PythonLogo from '../assets/logos/python.png';
import TypescriptLogo from '../assets/logos/typescript.png';
import JavascriptLogo from '../assets/logos/javascript.png';
import GithubLogo from '../assets/logos/github.png';
import GitLogo from '../assets/logos/git.png';
import DockerLogo from '../assets/logos/docker.png';
import DockerComposeLogo from '../assets/logos/docker-compose.png';
import '../styles/Skills.css';

export default function	Skills() {
	return (
		<div id="skills" className="bg-crowberryBlue p-[3%] px-[5%]">
			<h1 className="partTitle text-white first-letter:text-grassStainGreen text-[80px]">Compétences</h1>
			<span className="underlinedContent bg-grassStainGreen"></span>

			<p className="introductionText text-white first-letter:text-grassStainGreen text-[26px] leading-[28px] mt-[2%] w-[780px]">
				En tant que Développeur Junior, je suis en constant apprentissage de nouvelles technologies.
				Cela me permet de créer et de maintenir des applications stables et évolutives.
				Grâce à cet apprentissage continu, j'acquiers des compétences solides tout en étant capable
				de m'adapter aux besoins techniques variés des projets auxquels je contribue.
			</p>
			<div className="categorys flex flex-wrap w-[80%] place-content-between mt-5">
				{/* Frontend case */}
				<div className="category before:h-[120px] before:bg-grassStainGreen mt-[2%] pl-[20px]">
					<h1 className="first-letter:text-grassStainGreen text-white text-[28px] mb-[10px]">Frontend</h1>
					<div className="categoryLinks flex flex-wrap place-content-between">
						<a className="techLink"
							href="https://fr.react.dev/">
							<img src={ReactLogo} width={40} height={40} alt="React logo" />
							<span className="text-[20px]">ReactJS</span>
						</a>
						<a className="techLink"
							href="https://svelte.dev/">
							<img src={SvelteLogo} width={40} height={40} alt="Svelte logo" />
							<span className="text-[20px]">Svelte</span>
						</a>
						<a className="techLink"
							href="https://tailwindcss.com/">
							<img src={TailwindLogo} width={40} height={40} alt="TailwindCss logo" />
							<span className="text-[20px]">Tailwind</span>
						</a>
					</div>
				</div>

				{/* Backend case */}
				<div className="category before:h-[120px] before:bg-grassStainGreen mt-[2%] pl-[20px]">
					<h1 className="first-letter:text-grassStainGreen text-white text-[28px] mb-[10px]">Backend</h1>
					<div className="categoryLinks flex flex-wrap place-content-between">
						<a className="techLink"
							href="https://nestjs.com/">
							<img src={NestJSLogo} width={40} height={40} alt="NestJS logo" />
							<span className="text-[20px]">NestJS</span>
						</a>
						<a className="techLink"
							href="https://www.prisma.io/">
							<img src={PrismaLogo} width={40} height={40} alt="Prisma logo" />
							<span className="text-[20px]">Prisma</span>
						</a>
					</div>
				</div>

				{/* Programmation case */}
				<div className="category before:h-[120px] before:bg-grassStainGreen mt-[2%] pl-[20px]">
					<h1 className="first-letter:text-grassStainGreen text-white text-[28px] mb-[10px]">Programmation</h1>
					<div className="categoryLinks flex flex-wrap place-content-between">
						<a className="techLink"
							href="https://en.wikipedia.org/wiki/C_(langage)">
							<img src={CLogo} width={40} height={40} alt="C logo" />
							<span className="text-[20px]">C</span>
						</a>
						<a className="techLink"
							href="https://en.wikipedia.org/wiki/C++">
							<img src={CPPLogo} width={40} height={40} alt="C++ logo" />
							<span className="text-[20px]">C++</span>
						</a>
						<a className="techLink"
							href="https://www.python.org/">
							<img src={PythonLogo} width={40} height={40} alt="Python logo" />
							<span className="text-[20px]">Python</span>
						</a>
						<a className="techLink"
							href="https://www.typescriptlang.org/">
							<img src={TypescriptLogo} width={40} height={40} alt="Typescript logo" />
							<span className="text-[20px]">Typescript</span>
						</a>
						<a className="techLink"
							href="https://fr.wikipedia.org/wiki/JavaScript">
							<img src={JavascriptLogo} width={40} height={40} alt="Javascript logo" />
							<span className="text-[20px]">Javascript</span>
						</a>
					</div>
				</div>

				{/* Tools case */}
				<div className="category before:h-[120px] before:bg-grassStainGreen mt-[2%] pl-[20px]">
					<h1 className="first-letter:text-grassStainGreen text-white text-[28px] mb-[10px]">Outils</h1>
					<div className="categoryLinks flex flex-wrap place-content-between">
						<a className="techLink"
							href="https://github.com/">
							<img src={GithubLogo} width={40} height={40} alt="Github logo" />
							<span className="text-[20px]">Github</span>
						</a>
						<a className="techLink"
							href="https://git-scm.com/">
							<img src={GitLogo} width={40} height={40} alt="Git logo" />
							<span className="text-[20px]">Git</span>
						</a>
						<a className="techLink"
							href="https://www.docker.com/">
							<img src={DockerLogo} width={40} height={40} alt="Docker logo" />
							<span className="text-[20px]">Docker</span>
						</a>
						<a className="techLink"
							href="https://docs.docker.com/compose/">
							<img src={DockerComposeLogo} width={40} height={40} alt="Docker-compose logo" />
							<span className="text-[20px]">Docker Compose</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};
