// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expSlide {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;

	width: 300px;
	min-height: 350px;
	height: auto;
	padding: 20px;
	padding-top: 30px;
	margin-right: 5%;

	text-align: center;
	line-height: 30px;
	border-radius: 16px;
	background-color: #BDF337;
}

.typeBox {
	position: absolute;
	top: -10px;
	left: 20px;

	padding-inline: 15px;

	line-height: 30px;
	border-radius: 6px;
	color: #003748;
}

.typeBox[data-type="Personnel"] {
	background-color: #D6E3F4;
}

.typeBox[data-type="Professionnel"] {
	background-color: #FDE23D;
}

.typeBox[data-type="Scolaire"] {
	background-color: #FFDDB0;
}

.expSlide[data-type="Personnel"] {
	border: 4px solid #D6E3F4;
}

.expSlide[data-type="Professionnel"] {
	border: 4px solid #FDE23D;
}

.expSlide[data-type="Scolaire"] {
	border: 4px solid #FFDDB0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/SlideExperience.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,8BAA8B;CAC9B,mBAAmB;;CAEnB,YAAY;CACZ,iBAAiB;CACjB,YAAY;CACZ,aAAa;CACb,iBAAiB;CACjB,gBAAgB;;CAEhB,kBAAkB;CAClB,iBAAiB;CACjB,mBAAmB;CACnB,yBAAyB;AAC1B;;AAEA;CACC,kBAAkB;CAClB,UAAU;CACV,UAAU;;CAEV,oBAAoB;;CAEpB,iBAAiB;CACjB,kBAAkB;CAClB,cAAc;AACf;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,yBAAyB;AAC1B","sourcesContent":[".expSlide {\n\tposition: relative;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: space-between;\n\tmargin-bottom: 15px;\n\n\twidth: 300px;\n\tmin-height: 350px;\n\theight: auto;\n\tpadding: 20px;\n\tpadding-top: 30px;\n\tmargin-right: 5%;\n\n\ttext-align: center;\n\tline-height: 30px;\n\tborder-radius: 16px;\n\tbackground-color: #BDF337;\n}\n\n.typeBox {\n\tposition: absolute;\n\ttop: -10px;\n\tleft: 20px;\n\n\tpadding-inline: 15px;\n\n\tline-height: 30px;\n\tborder-radius: 6px;\n\tcolor: #003748;\n}\n\n.typeBox[data-type=\"Personnel\"] {\n\tbackground-color: #D6E3F4;\n}\n\n.typeBox[data-type=\"Professionnel\"] {\n\tbackground-color: #FDE23D;\n}\n\n.typeBox[data-type=\"Scolaire\"] {\n\tbackground-color: #FFDDB0;\n}\n\n.expSlide[data-type=\"Personnel\"] {\n\tborder: 4px solid #D6E3F4;\n}\n\n.expSlide[data-type=\"Professionnel\"] {\n\tborder: 4px solid #FDE23D;\n}\n\n.expSlide[data-type=\"Scolaire\"] {\n\tborder: 4px solid #FFDDB0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
