// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ----------------------------------------------
 * Generated by Animista on 2024-10-22 13:39:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
.socialButton:hover {
	filter: drop-shadow(0 0 3px black);
	animation: rotate-center 0.6s ease-in-out both;
}

@keyframes rotate-center {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA;;;;;mDAKmD;;AAEnD;;;;EAIE;AACF;CACC,kCAAkC;CAClC,8CAA8C;AAC/C;;AAEA;CACC;EACC,oBAAoB;CACrB;CACA;EACC,yBAAyB;CAC1B;AACD","sourcesContent":["/* ----------------------------------------------\n * Generated by Animista on 2024-10-22 13:39:23\n * Licensed under FreeBSD License.\n * See http://animista.net/license for more info. \n * w: http://animista.net, t: @cssanimista\n * ---------------------------------------------- */\n\n/**\n * ----------------------------------------\n * animation rotate-center\n * ----------------------------------------\n */\n.socialButton:hover {\n\tfilter: drop-shadow(0 0 3px black);\n\tanimation: rotate-center 0.6s ease-in-out both;\n}\n\n@keyframes rotate-center {\n\t0% {\n\t\ttransform: rotate(0);\n\t}\n\t100% {\n\t\ttransform: rotate(360deg);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
